/*

 Map and search functionality

 */

import { MarkerClusterer } from '../js/maps-marker-cluster';

var getAddress = function (data) {
    var address = [
        data.address1,
        data.address2,
        data.town,
        data.postcode,
    ].filter(function(line){
        return line != false
    });

    return address.join(', ');
};

const Geolocate = function () {
    if(typeof navigator.geolocation !== 'undefined') {
        navigator.geolocation.getCurrentPosition(function(position) {
            const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };

            $(document).trigger('userGeolocationReady', [coords]);
        });
    } else {
        const coords = {
            latitude: 51.51363389179955,
            longitude: -0.0959906205535
        };

        $(document).trigger('userGeolocationReady', [coords]);
    }
};

var PodAddressInfo = function (pod) {
    var self = this;

    self.pod      = pod;
    self.template = document.getElementById('lam-info-bubble').innerHTML;

    $(document).on('podLocationDataWasLoaded', function (ev, data) {
        $(document).off('podLocationDataWasLoaded');
        var info = self.parsePodInfo(data);
        var html = Mustache.render(self.template, info);

        $(document).trigger('podLocationTemplateReady', [html])
    });

    self.getPodDetails();
};

PodAddressInfo.prototype = {
    getPodDetails: function () {
        $.get('/ajax/pods/' + this.pod.id, function (data) {
            $(document).trigger('podLocationDataWasLoaded', [data])
        });
    },

    parsePodInfo: function (data) {
        return {
            id: data.address.id,
            name: data.address.name,
            address: this.getAddress(data.address.address),
            slug: data.address.slug,
            pods: data.pods
        }
    }
};

PodAddressInfo.prototype.getAddress = getAddress;

let userLocationCoordinates = null;

var LiveAvailabilityMap = function () {
    var self = this;

    self.podAddresses = podAddresses;
    self.map          = this.makeMap();
    self.markers      = this.addMarkers();
    self.cluster      = this.makeMarkerCluster();
    self.infoWindow   = this.makeInfoWindow();
    self.highlighted  = null;

    $(document).on('userGeolocationReady', function (ev, coords) {
        userLocationCoordinates = coords;
        var center = new google.maps.LatLng(coords.latitude, coords.longitude);
        self.map.panTo(center);
    });
};

LiveAvailabilityMap.prototype = {
    makeMap: function () {
        return new google.maps.Map(document.getElementById('map') , {
            zoom: 9,
            center: new google.maps.LatLng(51.51363389179955, -0.0959906205535),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });
    },

    makeMarkerCluster: function () {
        return new MarkerClusterer(this.map, this.markers, {
            imagePath: '/imgs/global.png',
            styles: [
                {backgroundPosition: '0 -250px'},
                {backgroundPosition: '-52px -250px'},
                {backgroundPosition: '-108px -250px'},
                {backgroundPosition: '-174px -250px'},
                {backgroundPosition: '-264px -250px'}
            ]
        });
    },

    makeMarker: function (pod, options) {
        var self = this;

        var marker = new google.maps.Marker({map: this.map});
        marker.setOptions(options);

        google.maps.event.addListener(marker, 'click', function() {
            new PodAddressInfo(pod);

            self.highlighted = marker;

            self.highlighted.setIcon(self.greenIcon());
            if (self.previousHighlighted) {
                self.previousHighlighted.setIcon(self.greyIcon());
            }
            self.previousHighlighted = self.highlighted;

            $(document).on('podLocationTemplateReady', function (ev, template) {
                $(document).off('podLocationTemplateReady');
                self.infoWindow.setOptions({content: template});
                self.infoWindow.open(self.map, marker);
            });
        });

        return marker;
    },

    makeInfoWindow: function () {
        var self = this;
        var window = new google.maps.InfoWindow();

        google.maps.event.addListener(window, 'closeclick', function(){
            self.highlighted.setIcon(self.greyIcon());
        });

        return window;
    },

    addMarkers: function () {
        var self = this;
        var markers = [];

        $.each(this.podAddresses, function (key, address) {
            var options = {
                position: new google.maps.LatLng(address.location.lat, address.location.lng),
                podId: address.id,
                content: '',
                payg: address.payg,
                icon: self.greyIcon()
            };

            markers.push(self.makeMarker(address, options));
        });

        return markers;
    },

    greyIcon: function () {
        return {
            url: '/imgs/global.png',
            size: new google.maps.Size(35, 51),
            anchor: new google.maps.Point(0, 50),
            origin: new google.maps.Point(50, 150)
        }
    },

    greenIcon: function () {
        return {
            url: '/imgs/global.png',
            size: new google.maps.Size(35, 51),
            anchor: new google.maps.Point(0, 50),
            origin: new google.maps.Point(0, 150)
        }
    },

    showMap: function (ev) {
        ev.preventDefault();
        $(this).addClass('btn-primary');
        $('#map-container, #map-legend').show();
        $('#list, #listPager').hide();
        $('.map-toggle .btn').not(this).addClass('btn-default').removeClass('btn-primary');
        google.maps.event.trigger(map, 'resize');
    }
};

var PodList = function () {
    var self = this;

    self.xhr          = null;
    self.template     = document.getElementById('podlist-template').innerHTML;
    self.pageTemplate = document.getElementById('podlist-pager-template').innerHTML
    self.currentPage  = 1;
    self.totalPages   = null;
    self.perPage      = 10;
    self.searchTerm   = null;
    self.$list        = $('#list');
    self.$pagination  = $('#listPager');

    $(document).on('podDataReady', function (ev, data) {
        self.totalPages = data.meta.pagination.page_count;
        self.renderList(data);
        self.renderPagination();
    });

    $(document).on('click', '.previous a', function(ev) {
        ev.preventDefault();
        self.searchTerm = $('#location').val();

        if (!self.searchTerm) {
            return;
        }

        if (self.currentPage !== 1) {
            self.currentPage--;
        }

        self.loadPodLocationsPlaces(
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.latitude,
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.longitude,
            self.currentPage,
            self.perPage,
            self.searchTerm
        );
    });

    $(document).on('click', '.next a', function(ev) {
        ev.preventDefault();
        self.searchTerm = $('#location').val();

        if (!self.searchTerm) {
            return;
        }

        if (self.currentPage < self.totalPages) {
            self.currentPage++;
        }

        self.loadPodLocationsPlaces(
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.latitude,
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.longitude,
            self.currentPage,
            self.perPage,
            self.searchTerm
        );
    });

    $(document).on('click', '#go', function (ev) {
        ev.preventDefault();
        self.searchTerm = $('#location').val();

        if (!self.searchTerm) {
            return;
        }

        if (self.xhr !== null) {
            self.xhr.abort();
        }

        self.$list.html('');
        self.$pagination.html('');

        self.currentPage = 1;

        self.loadPodLocationsPlaces(
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.latitude,
            (userLocationCoordinates === null) ? 0 : userLocationCoordinates.longitude,
            self.page,
            self.perPage,
            self.searchTerm
        );

        self.showList(ev);
    });
};

PodList.prototype = {
    loadPodLocationsPlaces: function (latitude, longitude, page, perPage, searchTerm) {
        this.xhr = $.getJSON(searchRoute, {
            location: latitude + "," + longitude,
            page: page,
            perPage: perPage,
            search: searchTerm
        }, function (data) {
            $(document).trigger('podDataReady', [data])
        });
    },

    parsePodLocationsPlaces: function (data) {
        var podLocationsPlaces = [];

        for (var i = 0; i < data.data.length; i++) {
            podLocationsPlaces[i] = {};
            Object.assign(podLocationsPlaces[i],
                {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    shortAddress: data.data[i].shortAddress,
                    latitude: data.data[i].latitude,
                    longitude: data.data[i].longitude,
                    slug: data.data[i].slug
                },
                (userLocationCoordinates) && {
                    distance: (Math.round(data.data[i].distance * 10) / 10) + 'km'
                },
                (data.data[i].scope === 'POD POINT') && {
                    isPod: true,
                    address: this.getAddress(data.data[i].address),
                    postcode: data.data[i].address.postcode,
                    addressSlug: data.data[i].address.slug
                },
                (data.data[i].scope !== 'POD POINT') && {
                    isPlace: true
                }
            );
        }

        if (data.data.length == 0) {
            podLocationsPlaces[0] = {
                noResults: true
            };
        }

        return podLocationsPlaces;
    },

    renderList: function (data) {
        var list = Mustache.render(this.template, {
            podLocationsPlaces: this.parsePodLocationsPlaces(data)
        });

        this.$list.html(list);
        $(document).on('click', '.place', function(ev) {
            const coords = {
                latitude: this.dataset.latitude,
                longitude: this.dataset.longitude
            };

            liveAvailabilityMap.showMap(ev);
            $(document).trigger('userGeolocationReady', [coords]);
            return false;
        });
    },

    renderPagination: function () {
        var pages = Mustache.render(this.pageTemplate, {
            firstPage: (this.currentPage === 1),
            page: this.currentPage,
            totalPages: this.totalPages,
            lastPage: (this.currentPage === this.totalPages)
        });

        this.$pagination.html(pages);
    },

    showList: function (ev) {
        ev.preventDefault();
        $(this).addClass('btn-primary');
        $('#map-container, #map-legend').hide();
        $('#list, #listPager').show();
        $('.map-toggle .btn').not(this).addClass('btn-default').removeClass('btn-primary');
    }
};

$(document).on('keypress', '#location', function() {
    $('#close-search').show();
});

if ($('#location').val() != '') {
    $('#close-search').show();
}

$(document).on('click', '#close-search', function(ev) {
    $('#location').val('');
    liveAvailabilityMap.showMap(ev);
    $('#close-search').hide();
});

PodList.prototype.getAddress = getAddress;

new Geolocate();
var liveAvailabilityMap = new LiveAvailabilityMap();
var podList = new PodList();
